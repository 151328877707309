/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { ArrowRight } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import BrowserNotificationImage from '../../../assets/images/browser_notification.svg';
import config from '../../../config';
import { useSelector } from '../../../interfaces/State';
import { selectUserSettings } from '../../../redux/selectors';
import DailyStreakScreenWrapper from '../DailyStreakScreenWrapper';

const BrowserNotificationScreen: React.FC<{ onExit: () => void }> = ({
  onExit,
}) => {
  const { t } = useTranslation();
  const userId = useSelector(selectUserSettings).get('id');
  const autoFocusRef = useRef(null);
  const { isAboveSmall } = useMediaQuery();

  useEffect(() => {
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - Browser Notification Screen Viewed',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        identity: userId,
      },
    });
  }, [userId]);

  const handleAllowReminder = () => {
    // trigger browser notification permission
    onExit();
  };

  return (
    <DailyStreakScreenWrapper initialFocus={autoFocusRef}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
        }}
      >
        <img
          src={BrowserNotificationImage}
          css={{ maxWidth: '100%' }}
          alt={t('DailyStreakModal.browserNotification.imageAlt')}
        />
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          [mediaQuery.aboveSmall]: { flexGrow: 0 },
          flexDirection: 'column',
          textAlign: 'center',
          marginBottom: tokens.spacing.xlarge,
          marginTop: tokens.spacing.large,
        }}
      >
        <Heading
          data-testid="learn-reminder-header"
          css={{
            fontSize: '32px',
          }}
        >
          {t('DailyStreakModal.browserNotification.title')}
        </Heading>

        <Paragraph>{t('DailyStreakModal.browserNotification.text')}</Paragraph>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexWrap: 'wrap',
          gap: tokens.spacing.medium,
          position: 'relative',
        }}
      >
        <Button
          css={{
            '&:focus': {
              outline: `${tokens.borderWidth.thin} solid ${tokens.colors.blue}`,
            },
          }}
          variant="regularOutline"
          fullWidth={!isAboveSmall}
          onClick={onExit}
          data-trackid="campus-browser-notification-skip"
          tabIndex={0}
        >
          {t('DailyStreakModal.browserNotification.skipReminder')}
        </Button>

        <Button
          css={{
            '&:focus': {
              outline: `${tokens.borderWidth.thin} solid ${tokens.colors.blue}`,
            },
          }}
          variant="cta"
          iconRight={isAboveSmall ? <ArrowRight /> : undefined}
          fullWidth={!isAboveSmall}
          onClick={handleAllowReminder}
          data-trackid="campus-browser-notification-allow-reminder"
          tabIndex={0}
        >
          {t('DailyStreakModal.browserNotification.allowReminder')}
        </Button>
      </div>
    </DailyStreakScreenWrapper>
  );
};

export default BrowserNotificationScreen;
