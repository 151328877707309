import React, { useCallback, useEffect } from 'react';

import config from '../../config';
import { useDispatch, useSelector } from '../../interfaces/State';
import { hideDailyStreakScreen } from '../../redux/actions';
import {
  selectDailyStreakScreenVisible,
  selectStreakDays,
  selectUserSettings,
} from '../../redux/selectors';

import BrowserNotificationScreen from './BrowserNotificationScreen/BrowserNotificationScreen';
import DailyStreakAppScreen from './DailyStreakAppScreen';
import DailyStreakFreezesScreen from './DailyStreakFreezesScreen';
import DailyStreakScreen from './DailyStreakScreen';
import { isStreakMilestone } from './isStreakMilestone';
import PickStreakGoalScreen from './PickStreakGoalScreen/PickStreakGoalScreen';
import useDailyStreakNavigation from './useDailyStreakNavigation';

const DailyStreakScreenHandler: React.FC = () => {
  const userId = useSelector(selectUserSettings).get('id');
  const streakDays = useSelector(selectStreakDays);
  const show = useSelector(selectDailyStreakScreenVisible);

  const dispatch = useDispatch();

  const onExit = useCallback(() => {
    dispatch(hideDailyStreakScreen());
  }, [dispatch]);

  const { onNextScreen, screen } = useDailyStreakNavigation({
    show,
    onExit,
  });

  useEffect(() => {
    if (!show || !window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - Streak Completed',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        identity: userId,
        isMilestone: isStreakMilestone(streakDays ?? 0),
        streakDays,
      },
    });
  }, [show, streakDays, userId]);

  if (!show) {
    return null;
  }

  if (screen === 'MOBILE_APP') {
    return <DailyStreakAppScreen onExit={onNextScreen} />;
  }

  if (screen === 'PICK_A_GOAL') {
    return <PickStreakGoalScreen onExit={onNextScreen} />;
  }

  if (screen === 'BROWSER_NOTIFICATION') {
    return <BrowserNotificationScreen onExit={onNextScreen} />;
  }

  if (screen === 'FREEZES_AWARDED') {
    return <DailyStreakFreezesScreen onExit={onNextScreen} />;
  }

  return <DailyStreakScreen onExit={onNextScreen} />;
};

export default DailyStreakScreenHandler;
