/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { ArrowRight } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../../config';
import DailyStreakScreenWrapper from '../DailyStreakScreenWrapper';
import {
  type DataForNextScreen,
  GOAL_SUBMITTED,
} from '../useDailyStreakNavigation';

import { FeedbackMessage } from './FeedbackMessage';
import { SelectGoalOptions } from './SelectGoalOptions';

export type GoalOptionType = {
  feedbackEmoji: string;
  feedbackTimes: string;
  key: string;
  value: string;
};

const sendHeapEvent = (option: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'heap_event',
      heap_event_name: 'Learn - Campus - Pick a Streak Goal Committed',
      heap_event_properties: {
        app_id: config.appName,
        event_category: 'learning',
        option,
      },
    });
  }
};

const goalOptions = [
  {
    key: 'goal1',
    value: '7',
    feedbackTimes: '3.4x',
    feedbackEmoji: '👏',
  },
  {
    key: 'goal2',
    value: '14',
    feedbackTimes: '4.6x',
    feedbackEmoji: '💪',
  },
  {
    key: 'goal3',
    value: '30',
    feedbackTimes: '4.9x',
    feedbackEmoji: '🏎️',
  },
  {
    key: 'goal4',
    value: '50',
    feedbackTimes: '5.9x',
    feedbackEmoji: '🚀',
  },
];

const PickStreakGoalScreen: React.FC<{
  onExit: (data?: DataForNextScreen) => void;
}> = ({ onExit }) => {
  const { t } = useTranslation();
  const autoFocusRef = useRef(null);
  const { isAboveSmall } = useMediaQuery();

  const [selectedGoal, setSelectedGoal] = React.useState<
    (typeof goalOptions)[number] | null
  >(null);

  const handleCommitButtonClick = () => {
    if (selectedGoal) {
      sendHeapEvent(`${selectedGoal.value} days`);
      onExit(GOAL_SUBMITTED);
    }
  };

  const handleSkipButtonClick = () => {
    sendHeapEvent('Nothing');
    onExit();
  };

  return (
    <DailyStreakScreenWrapper initialFocus={autoFocusRef}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          [mediaQuery.aboveSmall]: { flexGrow: 0 },
          flexDirection: 'column',
          textAlign: 'center',
          marginBottom: tokens.spacing.xlarge,
        }}
      >
        <Heading
          data-testid="pick-a-streak-goal-header"
          css={{
            marginTop: tokens.spacing.xlarge,
            fontSize: '32px',
          }}
        >
          {t('DailyStreakModal.pickGoal.header')}
        </Heading>

        <Paragraph
          css={{
            marginBottom: tokens.spacing.xlarge,
          }}
        >
          {t('DailyStreakModal.pickGoal.message')}
        </Paragraph>

        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: tokens.borderRadius.medium,
            color: tokens.colors.navy,
            width: isAboveSmall ? 400 : 300,
          }}
        >
          <div
            css={{
              backgroundColor: tokens.colors.greyMedium,
              padding: tokens.spacingNew.xsmall,
              borderTopRightRadius: tokens.borderRadius.medium,
              borderTopLeftRadius: tokens.borderRadius.medium,
            }}
          >
            <Heading
              css={{
                color: tokens.colors.navy,
                margin: 0,
              }}
              size="medium"
            >
              {t('DailyStreakModal.pickGoal.optionsHeader')}
            </Heading>
          </div>

          <div
            css={[
              css({
                backgroundColor: tokens.colors.white,
                borderBottomRightRadius: tokens.borderRadius.medium,
                borderBottomLeftRadius: tokens.borderRadius.medium,
              }),
            ]}
          >
            {goalOptions.map((option) => (
              <SelectGoalOptions
                key={option.key}
                option={option}
                onChange={setSelectedGoal}
                selectedGoal={selectedGoal}
              />
            ))}
          </div>

          <FeedbackMessage goal={selectedGoal} />
        </div>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexWrap: 'wrap',
          gap: tokens.spacing.medium,
          position: 'relative',
        }}
      >
        <Button
          css={{
            '&:focus': {
              outline: `${tokens.borderWidth.thin} solid ${tokens.colors.blue}`,
            },
          }}
          variant="regularOutline"
          fullWidth={!isAboveSmall}
          onClick={handleSkipButtonClick}
          data-trackid="campus-pick-streak-goal-skip"
          tabIndex={0}
        >
          {t('DailyStreakModal.pickGoal.skipCta')}
        </Button>

        <Button
          css={{
            '&:focus': {
              outline: `${tokens.borderWidth.thin} solid ${tokens.colors.blue}`,
            },
          }}
          variant="cta"
          iconRight={isAboveSmall ? <ArrowRight /> : undefined}
          fullWidth={!isAboveSmall}
          onClick={handleCommitButtonClick}
          data-trackid="campus-pick-streak-goal-continue"
          tabIndex={0}
          disabled={!selectedGoal}
        >
          {t('DailyStreakModal.pickGoal.submitCta')}
        </Button>
      </div>
    </DailyStreakScreenWrapper>
  );
};

export default PickStreakGoalScreen;
