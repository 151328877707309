import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ChapterRatingModal from './ChapterRatingModal';

type ChapterRatingProps = {
  chapterNumber: number;
  chapterTitle: string;
  onFinish: (...args: any[]) => any;
  onRate: (...args: any[]) => any;
};

const ChapterRating: React.FC<ChapterRatingProps> = ({
  chapterNumber,
  chapterTitle,
  onFinish,
  onRate,
}) => {
  const { t } = useTranslation();

  const handleRate = useCallback(
    (rating: number | null, feedbackMessage: string) => {
      onRate({ rating, feedbackMessage });
    },
    [onRate],
  );

  return (
    <ChapterRatingModal
      message={t('ChapterRating.message', {
        chapterNumber,
        chapterTitle,
      })}
      onSkip={onFinish}
      onRate={handleRate}
    />
  );
};

export default ChapterRating;
