/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { Cross } from '@datacamp/waffles/icon';
import { theme } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import ReactDOM from 'react-dom';

import { useCourseNotesWorkspaceUrl } from './hooks/useCourseNotesWorkspaceUrl';

const MIN_WIDTH = tokens.breakpoints.medium;
const OPTIMAL_WIDTH = '1360px';
const MAX_WIDTH = '60%';

const styles = {
  button: css({
    color: theme.text.subtle,
    backgroundColor: theme.background.contrast,
    position: 'absolute',
    top: 13,
    right: 5,
  }),
  container: css({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
  }),
  drawer: css({
    position: 'absolute',
    zIndex: tokens.zIndex.overlay,
    top: 0,
    height: '100%',
    width: `clamp(${MIN_WIDTH}, ${OPTIMAL_WIDTH}, ${MAX_WIDTH})`,
    transition: 'all 0.3s ease',
  }),
};

export const CourseNotes = ({
  closeCourseNotes,
  courseNotesOpened,
}: {
  closeCourseNotes: () => void;
  courseNotesOpened: boolean;
}) => {
  const { isAboveMedium } = useMediaQuery();
  const workspaceUrl = useCourseNotesWorkspaceUrl({
    type: 'embedded',
  });

  if (!isAboveMedium || workspaceUrl == null) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      css={styles.container}
      style={{
        pointerEvents: courseNotesOpened ? 'auto' : 'none',
      }}
    >
      <div
        css={styles.drawer}
        style={{
          right: courseNotesOpened ? 0 : `-100%`,
          opacity: courseNotesOpened ? 1 : 0,
        }}
      >
        <Button
          aria-label="Close course notes"
          onClick={closeCourseNotes}
          css={styles.button}
          icon={<Cross />}
          variant="plain"
        />
        <iframe
          title="Course Notes"
          width="100%"
          height="100%"
          src={workspaceUrl}
        />
      </div>
    </div>,
    document.body,
  );
};
