import { useCallback, useEffect, useMemo, useState } from 'react';

import { STORAGE_KEYS, useStorage } from '../../../helpers/useStorage';
import { useDispatch, useSelector } from '../../../interfaces/State';
import { getWorkspaceNotes, showModal } from '../../../redux/actions';
import {
  MODAL_TYPE,
  selectChapterNumber,
  selectCourseRef,
  selectExNumber,
  selectNotes,
  selectUserSettings,
} from '../../../redux/selectors';

export const useCourseNotes = () => {
  const dispatch = useDispatch();
  const courseSlug = useSelector(selectCourseRef);
  const { workspaceNotes, workspaceTemplate } = useSelector(selectNotes);

  const isLoading =
    workspaceTemplate == null ||
    workspaceNotes == null ||
    workspaceNotes.type === 'loading';

  const { setValue: setHasUsedCourseNotes, value: hasUsedCourseNotes } =
    useStorage(STORAGE_KEYS.NOTES_USED, false);
  const { setValue: setHasSeenPopup, value: hasSeenPopup } = useStorage(
    STORAGE_KEYS.NOTES_POPUP_SEEN,
    false,
  );
  const userSettings = useSelector(selectUserSettings);
  const hasSeenCampusTour = userSettings.get('has_seen_campus_tour');
  const totalXp = userSettings.get('total_xp');
  const exerciseNumber = useSelector(selectExNumber);
  const chapterNumber = useSelector(selectChapterNumber);
  const showCourseNotesPopup =
    !isLoading &&
    !hasSeenPopup &&
    !(hasUsedCourseNotes || workspaceNotes?.type === 'success') &&
    hasSeenCampusTour &&
    chapterNumber === 2 &&
    exerciseNumber === 2 &&
    totalXp > 3000;

  const [didClickNotes, setDidClickNotes] = useState(false);
  const [courseNotesOpened, setCourseNotesOpened] = useState(false);

  const onClosePopup = useCallback(() => {
    setHasSeenPopup(true);
  }, [setHasSeenPopup]);

  const openCourseNotes = useCallback(
    ({ variant }: { variant: 'drawer' | 'modal' }) => {
      setHasUsedCourseNotes(true);
      setHasSeenPopup(true);
      setDidClickNotes(true);

      if (variant === 'modal') {
        dispatch(showModal({ modal: MODAL_TYPE.COURSE_NOTES }));
      } else {
        setCourseNotesOpened(true);
      }
    },
    [setHasUsedCourseNotes, setHasSeenPopup, dispatch],
  );

  const closeCourseNotes = useCallback(() => {
    setCourseNotesOpened(false);
  }, []);

  useEffect(() => {
    if (workspaceTemplate == null) {
      return;
    }

    dispatch(getWorkspaceNotes({ courseSlug, workspaceTemplate }));
  }, [workspaceTemplate, courseSlug, dispatch]);

  return useMemo(
    () => ({
      courseNotesOpened,
      closeCourseNotes,
      didClickNotes,
      onClosePopup,
      openCourseNotes,
      showCourseNotesPopup,
      workspaceTemplate,
      workspaceNotes,
    }),
    [
      courseNotesOpened,
      closeCourseNotes,
      didClickNotes,
      onClosePopup,
      openCourseNotes,
      showCourseNotesPopup,
      workspaceTemplate,
      workspaceNotes,
    ],
  );
};
