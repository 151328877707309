import qs from 'qs';
import { useMemo } from 'react';

import config from '../../../config';
import { useSelector } from '../../../interfaces/State';
import unreachable from '../../../interfaces/unreachable';
import type {
  WorkspaceSourceCreationInfo,
  WorkspaceTemplate,
} from '../../../redux/reducers/notes';
import { selectCourseRef, selectNotes } from '../../../redux/selectors';

const getQueryParamsForWorkspaceTemplate = ({
  courseSlug,
  workspaceTemplate,
}: {
  courseSlug: string;
  workspaceTemplate: WorkspaceTemplate;
}): WorkspaceSourceCreationInfo & { utm_term: string } => {
  switch (workspaceTemplate._tag) {
    case 'course':
      return {
        _tag: 'course',
        utm_term: `course_source_${workspaceTemplate.courseId}_${courseSlug}`,
        courseId: workspaceTemplate.courseId,
      };
    case 'template':
      return {
        _tag: 'template',
        utm_term: `${workspaceTemplate.key}_${courseSlug}`,
        templateKey: workspaceTemplate.key,
      };
    default:
      return unreachable(workspaceTemplate);
  }
};

export const useCourseNotesWorkspaceUrl = ({
  type,
}: {
  type: 'embedded' | 'full';
}) => {
  const courseSlug = useSelector(selectCourseRef);
  const { workspaceTemplate } = useSelector(selectNotes);

  return useMemo(() => {
    if (workspaceTemplate == null) {
      return null;
    }

    let queryParams: Record<string, unknown> = {
      utm_source: 'learn-hub',
      utm_medium: 'in_product',
      utm_content: 'on_campus_exercises',
      openIfExistsKey: courseSlug,
      openIfExists: true,
      editorVersion: 'dcStudioEditor',
      title: workspaceTemplate.title,
      ...getQueryParamsForWorkspaceTemplate({ workspaceTemplate, courseSlug }),
    };

    if (type === 'embedded') {
      queryParams = {
        ...queryParams,
        disabledMenuBar: true,
        showLeftNavigation: false,
        showPoweredByDatalab: true,
        showOpenFullScreenInFooter: true,
        showAutoSaveState: false,
        showOutlineButton: false,
        showRightSidePanelToggle: false,
        showFullRunButton: true,
        showScheduleRunButton: false,
        showShareWorkspaceButton: false,
        showViewModeSwitcher: false,
      };
    }

    return `${config.urls.datalab}/new?${qs.stringify(queryParams)}`;
  }, [courseSlug, type, workspaceTemplate]);
};
